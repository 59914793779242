<template>
<div class="main category-lesson">
    <Switcher />
</div>
</template>
<script>
import Switcher from './compoments/switcher.vue'
export default {
    name: "lesson-list",
    components: { 
        Switcher
    },
}
</script>